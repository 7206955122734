.main-app-mobile-desc {
  margin-top: 80px;  
  /* margin-bottom: 147px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.mobile-app-heading-span{
    margin-bottom: 13px;
    text-align: center;
}
.mobile-app-heading-span span {
  color: #d6ddee;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}
.mobile-background-container {
    
  width: 157px;
  height: 130px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  border-radius: 24px;
  background: #1e2137;
  position: relative;
}
.mobile-background-container img {
  width: 78.104px;
  height: 78.266px;
  flex-shrink: 0;
  position: absolute;
  top: -20px;
}
.mobile-background-container span {
  position: absolute;
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  bottom: 30px;
}
.mobile-paragraphs {
  max-width: 334px;
  display: flex;
  flex-direction: column;
  color: #d6ddee;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  opacity: 0.7;
  gap: 30px;
}

@media screen and (max-width: 576px) {
  .flex-end-left-container,
  .flex-end-right-container {
    height: auto;
  }
  .right-app-container {
    justify-content: center;
  }
}
