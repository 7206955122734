.main-container-navbar {
  background:#181B2E;
  width: 100%;
  height: 85px;
  position: fixed ;
  z-index: 999;
  top: 0;
}
.navbar-container {
  padding: 24px 40px 5px 40px;
  display: flex;
  justify-content: space-between;
}
.left-navbar {
  cursor: pointer;
  display: flex;
  width: 210px;
  gap: 11.82px;
  align-items: center;
}
.left-navbar img {
  height: 47px;
  width: 47.27px;
}
.left-navbar p {
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 20.695px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1.449px;
}
.left-navbar span {
  color: #ed628b;
}
.mid-navbar {
  display: flex;
  align-items: center;
  position: relative
}
.mid-navbar img {
  position: absolute;
  top: -24px;
}
.mid-navbar ul {
  padding: 0;
  display: flex;
  align-items: center;
  gap: 24px;
}
.mid-navbar ul li {
  user-select: none;
  color: #f2f5ff;
  list-style: none;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  cursor: pointer;
}
.mid-navbar ul li:hover{
  color: #ED628B;
}
.right-navbar {
  display: flex;
  align-items: center;
  gap: 40px;
}
.right-navbar select {
  background: none;
}
.select-lag {
  border-color: transparent;
  background: none;
  color: #f2f5ff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.28px;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  border: 0px;
}
.select-lag option {
  margin: 20px;
  color: #f2f5ff;
  border: transparent;
  border-radius: 4px;
  background: #22263d;
}

@media screen and (max-width: 1024px) {
  .mid-navbar,.right-navbar select {
    display: none;
  }
}
@media only screen and (max-width:576px){
  .navbar-container{
      padding: 40px 20px 5px 20px;
  }
  .left-navbar{
      gap: 8.3px;
  }
  .left-navbar img{
      width: 32.13px;
      height: 32.13px;
  }
  .left-navbar p{
      font-size: 14px;
  }
  
  .right-navbar button{
      width: 40px;
      height: 40px;
      border-radius: 8px;
  }
  .Navbar-btn a span{
    display: none;
  }
}