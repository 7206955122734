.partner-div {
  scroll-margin-top: 90px;
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  gap: 70px;
  margin-top: 25px;
  justify-content: space-between;
  margin: 25px auto 0px auto;
}

.partner-div > span {
  font-family: Livvic;
  font-size: 64px;
  color: #d6ddee;
  font-weight: 900;
  line-height: 64px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}

.menu-div-partners {
  background: #1e2137;
  border-radius: 24px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 10px 20px;
}
.menu-div-partners > span {
  color: white;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 1400px) {
  .partner-div{
    max-width: 1360px;
  }
}
@media screen and (min-width:856px) {
  .menu-div-partners {
    gap: 10px;
  }
  .menu-div-partners > span:first-child{
    margin-bottom: 10px;
    position: relative;
    font-weight: 700;
  }
  .menu-div-partners > span:nth-child(2){
    margin-top: 10px;
  }
  .menu-div-partners > span:first-child::after{
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    content: "";
    bottom: -15px;
    opacity: .8;
    width: 80%;
    height: 2px;
    background-color: #d6ddee;
  }
}
.active-partners {
  background: #5f84f5;
}
.menu-div-partners > span:not(.active-partners):hover {
  color: #4d6bc6;
}

.partners-flexbox {
  /* grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto;
  display: grid; */

  flex-wrap: wrap;
  gap: 20px;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
}
.partners-flexbox > .partners-image-div {
  height: 160px;
  width: 160px;
  
  display: flex;
  align-items: center;
  justify-items: center;
}


.parent-div-partners {
  display: flex;
  gap: 40px;
}


/*  */
h1 {
  font-size: 25px;
  text-align: left;
  text-transform: capitalize;
}
.service-box {
  position: relative;
  margin-bottom: 10px;
  border-radius: 20px;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.service-icon {
  background: #1e2137;
  height: 160px;
  width: 160px;
  text-align: center;
  transition: all 0.5s ease;
  border-radius: 24px;
}
.front-content{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
}
.front-content img{
  width: 60px;
  aspect-ratio: 1/1;
 }
 .front-content span{
  color: #fff;
  font-family: Livvic;
 }

.service-content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  height: 160px;
  width: 160px;
  background: #d6ddee;
  border-radius: 24px;
  text-align: center;
  transition: all 0.5s ease;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateY(-90px) rotateX(90deg);
}
.service-content div{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-content button{
  background: #1e2137;
  cursor: pointer;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 20px;
}
.service-box .service-icon .front-content {
  position: relative;
  top: 80px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.service-box:hover .service-icon {
  opacity: 0;
  -webkit-transform: translateY(-90px) rotateX(90deg);
  -moz-transform: translateY(-90px) rotateX(90deg);
  -ms-transform: translateY(-90px) rotateX(90deg);
  -o-transform: translateY(-90px) rotateX(90deg);
  transform: translateY(90px) rotateX(-90deg);
}
.service-box:hover .service-content {
  opacity: 1;
  -webkit-transform: rotateX(0);
  -moz-transform: rotateX(0);
  -ms-transform: rotateX(0);
  -o-transform: rotateX(0);
  transform: rotateX(0);
}

/*  */



@media screen and (min-width: 1440px) {
  .parent-div-partners {
    width: 100%;
    justify-content: space-between;
  }
  .partners-flexbox {
    max-width: 1024px;
    width: 100%;
  }
}



@media screen and (max-width: 855px) {
  .parent-div-partners {
    flex-direction: column;
    row-gap: 40px;
  }
  .menu-div-partners {
    margin: auto;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .menu-div-partners > span {
    max-width: 100px;
    font-size: 16px;
  }
}

@media screen and (max-width: 590px) {
  .partner-div {
    width: calc(100% - 50px);
    margin-top: 80px;
    gap: 40px;
  }
  .partners-flexbox {
    row-gap: 10px;
    column-gap: 10px;
  }
  .partner-div > span{
    font-size: 32px;
    text-align: center;
  }
  .partners-flexbox > .partners-image-div {
    height: 140px;
    width: 140px;
  }
  .service-icon,
  .service-content{
    width: 140px;
    height: 140px;
  }
  .partners-flexbox > .partners-image-div > img{
    height: 120px;
    width: 120px;
  }
}
