.btn {
  transition: .3s ease;
  cursor: pointer;
  border: none;
  width: 184px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 17.6px;
  background: #ed628b;
  color: #f2f5ff;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}
.btn:hover{
  background: #C04F71
}
.btn a{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
}
@media only screen and (max-width:576px){
  .btn{
    font-size: 12px;
    border-radius: 12.95px;
  }
}
