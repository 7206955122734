*{
  user-select: none;
}
html{
  background:#181B2E;
  transition: all .3s linear;
}
.Toastify__close-button{
  display: none;
}
.Toastify__toast{
  background: #1e2137 !important;
  border-radius: 17.6px !important;
}