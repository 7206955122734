* {
  padding: 0%;
  margin: 0%;
}

.div-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.BubbleAnimTop {
  position: absolute;
  top: -24px;
  left: -260px;
}
.BubbleAnimBottom {
  position: absolute;
  bottom: -75px;
  right: -150px;
}
.main-Container {
  padding-top: 105px;
  position: relative;
  overflow: hidden;
}
.card-main-container {
  scroll-margin-top: 105px;
  margin: auto;
  padding: 50px 0;
  width: calc(100% - 80px);
  /* height: 693px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: #1e2137;
  background-image: url("../svgs//heroBg.svg");
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(4px);
  position: relative;
}
.top-main-content {
  padding: 0px 93px 0px 95px;
}
.top-main-content strong {
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 8vw;
  white-space: nowrap;
  font-style: normal;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
}
.top-main-content p {
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.8px;
}
.top-main-content .by {
  color: #5c6081;
}
.top-main-content .lvl {
  margin-left: 10px;
  color: #d6ddee;
  text-transform: uppercase;
}
.top-main-content .swp {
  color: #ed628b;
}
.mid-images {
  margin-top: -5vw;
  flex-shrink: 0;
  /* position: absolute; */
  /* filter: drop-shadow(21px 21px 143px #dc254b); */
  /* top: 8vw; */
}
.mid-images img {
  filter: drop-shadow(21px 21px 143px #dc254b);
}
.bottom-buttons {
  display: flex;
  /* position: absolute; */
  bottom: 40px;
  gap: 16px;
}
.gtstarted {
  background: #5f84f5;
}
.gtstarted:hover {
  background: #4d6bc6;
}
.whitepaper {
  background: transparent;
  border: 1px solid #5f84f5;
}
.whitepaper:hover {
  background: #5f84f5;
}
.milestones {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}
.milestones-innerDiv {
  width: calc(100% - 80px);
  margin: auto;
}
.milestones-innerDiv .flex {
  margin-top: 80px;
  display: flex;
  gap: 20px;
  background: url("../svgs/milestoneLinesBg.svg") no-repeat;
  background-position: right;
  justify-content: center;
  flex-wrap: wrap;
}
.milestones-innerDiv > p {
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

.div-milestones-info {
  position: relative;
  width: 440px;
  height: fit-content;
}

.milestones-btn {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 55px;
  top: -41px;
  box-shadow: 0px 33px 80px 0px #ed628b;
}
.main-svg-div {
  padding: 60px 33px 0 33px;
  margin-top: 10px;
  height: 270px;
  width: 374px;
  background-image: url("../svgs/milestonesCard.svg");
  background-repeat: no-repeat;
}
.main-svg-div span {
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.main-svg-div p {
  margin-top: 20px;
  width: 383px;
  opacity: 0.7;
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.app-description-main-container span {
  font-family: Livvic;
  font-size: 64px;
  color: #d6ddee;
  font-weight: 900;
  line-height: 64px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;
}

.app-description-main-container{
  scroll-margin-top: 115px;
  margin-top: 122px;
  margin-bottom: 100px;
}

.inner-app-container {
  width: calc(100% - 80px);
  display: flex;
  margin: auto;
  gap: 20px;
}


.left-app-container p {
  color: #d6ddee;
  font-family: Livvic;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.7;
}
.p-top {
  margin-top: 50px;
}
.p-mid {
  margin-top: 24px;
  margin-bottom: 40px;
}
.right-app-container {
  display: flex;
  gap: 20px;
  position: relative;
}

.BubbleAnimTopRightContainer {
  position: absolute;
  right: 150px;
}
.BubbleAnimTopRightContainer2 {
  position: absolute;
  right: 150px;
  top: 150px;
}

.flex-end-left-container {
  height: 574px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  gap: 33px;
}
.flex-end-right-container .background-container,
.flex-end-left-container .background-container {
  width: 325px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  flex-shrink: 0;
  position: relative;
  border-radius: 24px;
  background: #1e2137;
}
.background-container img {
  filter: drop-shadow(21px 21px 143px #dc254b);
  position: absolute;
  top: -12px;
}
.flex-end-right-container {
  height: 574px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  gap: 33px;
}
.background-container span {
  position: absolute;
  top: 118px;
  color: #d6ddee;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Livvic;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.Inquiry-container {
  scroll-margin-top: 130px;
  margin-bottom: 128px;
  color: #fff;
  font-family: Livvic;
  margin-top: 100px;
  /* padding: 0 40px; */
}
.inquiry-inner {
  width: fit-content;
  /* width: calc(100% - 80px); */
  margin: auto;
  position: relative;
}
.Inquiry-container .inquiry-btn {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 55px;
  top: 0px;
  box-shadow: 0px 33px 80px 0px #ed628b;
  z-index: 1;
}
.inquiry-svg-div {
	background: url(../svgs/inquiry.svg) no-repeat;
  background-size: contain;
  margin: auto;
	width: 1362px;
	height: 317px;
	padding: 124px 30px 0 52px;
	text-transform: uppercase;
	box-sizing: border-box;
}
.inquiry-inner span {
  font-family: Livvic;
  font-size: clamp(1rem, 2.3vw, 4rem);
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0.02em;
}
.inquiry-inner p {
  margin-top: 13px;
  font-family: Livvic;
  font-size: clamp(2rem, 4vw, 4.43rem);
  font-weight: 900;
  line-height: 64px;
  letter-spacing: 0.02em;
  color: #ed628b;
}
/* .inquiry-svg-div img{
  position: absolute;
  top: -48px;
  right: 32px;
  height: 317px;
  width: 419px;
  filter: drop-shadow(21px 21px 143px #dc254b);
} */
@media screen and (max-width:1400px) {
  .Inquiry-container .inquiry-btn{
    left: 45px;
    top: -10px;
  }
  .inquiry-svg-div{
    width: 1180px;
    height: 275px;
  }
}
@media screen and (max-width: 1200px) {
  .inner-app-container{
    flex-direction: column;
    align-items: center;
  }
  .inquiry-svg-div{
    padding: 94px 30px 0 52px;
    width: 1004px;
    height: 235px;
  }
  .Inquiry-container .inquiry-btn {
    left: 35px;
  }
}
@media screen and (min-width: 1400px) {
  .inner-app-container,
  .card-main-container{
    max-width: 1360px;
  }
    .milestones-innerDiv p{
      max-width: 1360px !important;
      margin: auto !important;
    }
}
@media screen and (max-width: 1024px) {
  .top-main-content{
    display: flex;
    flex-direction: column-reverse;
  }
  .inquiry-svg-div{
    padding: 65px 30px 0 52px;
    width: 748px;
    height: 176px;
  }
  .Inquiry-container .inquiry-btn {
    left: 18px;
    top: -25px;
  }
}
@media screen and (max-width: 768px) {
  .inquiry-svg-div{
    padding: 37px 0px 0 15px;
    width: 556px;
    height: 130px;
  }
  .Inquiry-container .inquiry-btn {
    left: 6px;
    top: -37px;
  }
  .inquiry-inner p{
    margin: 0;
  }
}
@media only screen and (max-width: 576px) {
  .documents-btn {
    width: 150px !important;
  }
  .btn {
    width: 115px;
    height: 48px;
  }
  .top-main-content {
    flex-direction: column;
    text-align: center;
  }
  .top-main-content strong {
    font-size: 10vw;
    -webkit-order: 2;
    order: 2;
  }
  .mid-images {
    top: 162px;
  }
  .mid-images img {
    width: 266px;
    height: 266px;
  }
  .bottom-buttons {
    bottom: 20px;
    gap: 8px;
  }
  .milestones-innerDiv .flex {
    flex-direction: column;
  }
  .div-milestones-info {
    width: 335px;
    margin: 64px auto 0;
    height: 220px;
  }
  .div-milestones-info.long {
    height: 330px;
  }
  .milestones-btn {
    left: 60px;
    width: 48px;
    height: 48px;
  }
  .milestones-innerDiv {
    width: 100%;
    padding: 60px 30px 43px 30px;
    padding: 0;
    margin: auto;
  }
  .milestones-innerDiv > p {
    text-align: center;
    font-size: 32px;
  }
  .main-svg-div {
    width: fit-content;
    height: 210px;
    background: url("../svgs/milestonesMobileCard.svg") no-repeat;
    padding: 60px 10px 0 10px;
  }
  .div-milestones-info.long .main-svg-div {
    height: 272px;
    background: url("../svgs/milestoneMobileLong.svg") no-repeat;
  }
  .main-svg-div p {
    width: 100%;
    margin-top: 15px;
    font-size: 16px;
  }
  .Inquiry-container {
    margin-top: 147px;
    margin-bottom: 80px;
    padding: 0%;
  }
  .inquiry-svg-div {
    background: url("../svgs/inquiryMobile.svg") no-repeat;
    width: 335px;
    height: 336px;
    padding: 50px 20px 110px 20px;

  }
  .Inquiry-container .inquiry-btn {
    top: -45px;
    left: 55px;
  }
  .inquiry-inner {
    width: 335px;
    margin: auto;
  }
  .inquiry-inner span {
    font-size: 16px;
  }
  .inquiry-inner p {
    font-size: 22px;
  }
  .inquiry-svg-div img {
    bottom: 0;
    top: auto;
    right: 35px;
    width: 267px;
    height: 202px;
  }
  .app-description-main-container span{
    font-size: 32px;
    text-align: center;
  }
}
