.main-container-footer {
  padding: 40px 40px;
  color: white;
  font-family: Livvic;
}
.footer-container {
  width: 100%;
  display: flex;
  column-gap: 26px;
  justify-content: space-between;
}
.footer-left .Copyright, .Copyright-mobile {
  font-family: Livvic;
  color: #D6DDEE;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.02em;
  margin: 37px 0 0 0;
  opacity: 0.7;
}
.Copyright-mobile{
  display: none;
  margin: 0%;
}
.footer-logo {
  display: flex;
  align-items: center;
  gap: 11.82px;
}
.footer-logo img {
  height: 47px;
  width: 47.27px;
}
.footer-logo p {
  color: #d6ddee;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 20.695px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 1.449px;
}
.footer-logo p span {
  color: #ed628b;
}
.social-media {
  margin-top: 28px;
  display: flex;
  gap: 8px;
}

.social-media button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 10px;
  border-radius: 9px;
  border: none;
  background-color: #D6DDEE;
  cursor: pointer;
}
.social-media button:hover{
  transition: 0.3s ease-in;
  background-color:  #ED628B;
}

.footer-mid {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
}
.support-menu {
  font-family: Livvic;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.08em;
}
.footer-mid div {
  display: flex;
  flex-direction: column;
}

.footer-container a {
  color: #fff;
  text-decoration: none;
}
.footer-container .bold {
  text-transform: uppercase;
  font-family: Livvic;
  font-size: 16px;
  font-weight: 900;
  line-height: 32px;
  letter-spacing: 0.08em;
  text-align: center;
}
.footer-right{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}
.footer-right p {
  font-family: Livvic;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #D6DDEE;
  opacity: 0.7;
}
.footer-right .subscribe {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #0F1223;
  width: 556px;
  height: 48px;
  border-radius: 32px 16px 16px 32px;
}

.footer-right .subscribe-btn {
  width: 149px;
  height: 40px;
  top: 3082px;
  border-radius: 8px;
  margin-right: 4px;
}

.footer-right input {
  width: auto;
  color: #fff;
  line-height: 35px;
  background: transparent;
  outline: 0;
  border: 0;
  flex-grow: 1;
}
.footer-right .at {
  margin-left: 11px;
  width: 32px;
  height: 32px;
  border-radius: 40px;
  padding-bottom: 4px;
}
@media screen and (max-width:1400px) {
  .footer-right .subscribe{
    width: 100%;
  }
}
@media only screen and (max-width:576px){
  .footer-container{
    flex-direction: column;
    height: 500px;
  }
  .footer-logo img{
    height: 32px;
    width: 32px;
  }
  .footer-logo p{
    font-size: 14px;
  }
  .social-media{
    margin-top: 31px;
  }
  .footer-mid .bold{
    display: none;
  }
  .footer-right .subscribe{
    margin-top: 29px;
    width: 100%;
  }
  .footer-right input{
    width: auto;
  }
  .footer-right .subscribe-btn{
    width: 40px;
    height: 40px;
  }
  .footer-left .Copyright{
    display: none;
  }
  .Copyright-mobile{
    display: block;
    font-size: 14px;
    text-align: center;
  }
}