@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
.custom-select {
    min-width: 70px;
    position: relative;
    font-family: Arial;
}

.custom-select select {
    display: none;
}

/*style the arrow inside the select element:*/
.select-selected:after {
    position: absolute;
    content: "\F282";
    font-family:'Bootstrap-icons';
    right: 0;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
    transform: rotate(180deg);

}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
    color: #ffffff;
    padding: 8px 26px 8px 10px;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
    transition: all .3s ease-in-out;
}

/*style items (options):*/
.select-items {
    position: absolute;
    background: #22263D; 
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-radius: 4px;
}

/*hide the items when the select box is closed:*/
.select-hide {
    display: none;
}

.select-items div:hover,
.same-as-selected {
    background-color: #0000005b;
}
